// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_option from "rescript/lib/es6/js_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Json_Decode$JsonCombinators from "./Json_Decode.bs.js";

var ParseError = /* @__PURE__ */Caml_exceptions.create("Json-JsonCombinators.ParseError");

function parse(str) {
  try {
    return {
            TAG: /* Ok */0,
            _0: JSON.parse(str)
          };
  }
  catch (raw_ex){
    var ex = Caml_js_exceptions.internalToOCamlException(raw_ex);
    if (ex.RE_EXN_ID === Js_exn.$$Error) {
      return {
              TAG: /* Error */1,
              _0: Js_option.getWithDefault("Unknown error", ex._1.message)
            };
    }
    throw ex;
  }
}

function parseExn(str) {
  try {
    return JSON.parse(str);
  }
  catch (raw_ex){
    var ex = Caml_js_exceptions.internalToOCamlException(raw_ex);
    if (ex.RE_EXN_ID === Js_exn.$$Error) {
      throw {
            RE_EXN_ID: ParseError,
            _1: Js_option.getWithDefault("Unknown error", ex._1.message),
            Error: new Error()
          };
    }
    throw ex;
  }
}

var Encode;

var Decode;

var decode = Json_Decode$JsonCombinators.decode;

export {
  Encode ,
  Decode ,
  ParseError ,
  decode ,
  parse ,
  parseExn ,
}
/* No side effect */
