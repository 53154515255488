// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "rescript/lib/es6/array.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var DecodeError = /* @__PURE__ */Caml_exceptions.create("Json_Decode-JsonCombinators.DecodeError");

function expected(kind, json) {
  throw {
        RE_EXN_ID: DecodeError,
        _1: "Expected " + kind + ", got " + JSON.stringify(json) + "",
        Error: new Error()
      };
}

var $$Error = {
  expected: expected
};

function custom(f) {
  return f;
}

function id(json) {
  return json;
}

function $$float(json) {
  if (typeof json !== "number") {
    expected("float", json);
  }
  return json;
}

function $$int(json) {
  if (typeof json !== "number") {
    expected("int", json);
  }
  var truncated = (json | 0);
  if (truncated !== json || !Number.isFinite(json)) {
    expected("int", json);
  }
  return json;
}

function bool(json) {
  if (typeof json !== "boolean") {
    expected("bool", json);
  }
  return json;
}

function string(json) {
  if (typeof json !== "string") {
    expected("string", json);
  }
  return json;
}

function array(decode) {
  return function (json) {
    if (!Array.isArray(json)) {
      expected("array", json);
    }
    var target = (new Array(json.length));
    for(var i = 0 ,i_finish = json.length; i < i_finish; ++i){
      try {
        var value = decode((json[i]));
        target[i] = value;
      }
      catch (raw_msg){
        var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
        if (msg.RE_EXN_ID === DecodeError) {
          throw {
                RE_EXN_ID: DecodeError,
                _1: "" + msg._1 + "\n\tin array at index " + String(i) + "",
                Error: new Error()
              };
        }
        throw msg;
      }
    }
    return target;
  };
}

function list(decode) {
  return function (json) {
    return $$Array.to_list(array(decode)(json));
  };
}

function option(decode) {
  return function (json) {
    if (json === null) {
      return ;
    } else {
      return Caml_option.some(decode(json));
    }
  };
}

function date(json) {
  return new Date(string(json));
}

function tuple2(decodeA, decodeB) {
  return function (json) {
    if (!Array.isArray(json)) {
      expected("array", json);
    }
    if (json.length !== 2) {
      throw {
            RE_EXN_ID: DecodeError,
            _1: "Expected array of length 2, got array of length " + String(json.length) + "",
            Error: new Error()
          };
    }
    try {
      return [
              decodeA(json[0]),
              decodeB(json[1])
            ];
    }
    catch (raw_msg){
      var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
      if (msg.RE_EXN_ID === DecodeError) {
        throw {
              RE_EXN_ID: DecodeError,
              _1: "" + msg._1 + "\n\tin pair",
              Error: new Error()
            };
      }
      throw msg;
    }
  };
}

function tuple3(decodeA, decodeB, decodeC) {
  return function (json) {
    if (!Array.isArray(json)) {
      expected("array", json);
    }
    if (json.length !== 3) {
      throw {
            RE_EXN_ID: DecodeError,
            _1: "Expected array of length 3, got array of length " + String(json.length) + "",
            Error: new Error()
          };
    }
    try {
      return [
              decodeA(json[0]),
              decodeB(json[1]),
              decodeC(json[2])
            ];
    }
    catch (raw_msg){
      var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
      if (msg.RE_EXN_ID === DecodeError) {
        throw {
              RE_EXN_ID: DecodeError,
              _1: "" + msg._1 + "\n\tin pair",
              Error: new Error()
            };
      }
      throw msg;
    }
  };
}

function tuple4(decodeA, decodeB, decodeC, decodeD) {
  return function (json) {
    if (!Array.isArray(json)) {
      expected("array", json);
    }
    if (json.length !== 4) {
      throw {
            RE_EXN_ID: DecodeError,
            _1: "Expected array of length 4, got array of length " + String(json.length) + "",
            Error: new Error()
          };
    }
    try {
      return [
              decodeA(json[0]),
              decodeB(json[1]),
              decodeC(json[2]),
              decodeD(json[3])
            ];
    }
    catch (raw_msg){
      var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
      if (msg.RE_EXN_ID === DecodeError) {
        throw {
              RE_EXN_ID: DecodeError,
              _1: "" + msg._1 + "\n\tin pair",
              Error: new Error()
            };
      }
      throw msg;
    }
  };
}

function dict(decode) {
  return function (json) {
    if (typeof json !== "object" || Array.isArray(json) || json === null) {
      expected("object", json);
    }
    try {
      return Js_dict.map(decode, json);
    }
    catch (raw_msg){
      var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
      if (msg.RE_EXN_ID === DecodeError) {
        throw {
              RE_EXN_ID: DecodeError,
              _1: "" + msg._1 + "\n\tin dict'",
              Error: new Error()
            };
      }
      throw msg;
    }
  };
}

function field(key, decode) {
  return function (json) {
    if (typeof json !== "object" || Array.isArray(json) || json === null) {
      expected("object", json);
    }
    if (!(key in json)) {
      throw {
            RE_EXN_ID: DecodeError,
            _1: "" + key + " required",
            Error: new Error()
          };
    }
    try {
      return decode((json[key]));
    }
    catch (raw_msg){
      var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
      if (msg.RE_EXN_ID === DecodeError) {
        throw {
              RE_EXN_ID: DecodeError,
              _1: "" + msg._1 + "\n\tat field '" + key + "'",
              Error: new Error()
            };
      }
      throw msg;
    }
  };
}

function object(f) {
  return function (json) {
    if (typeof json !== "object" || Array.isArray(json) || json === null) {
      throw expected("object", json);
    }
    var optional = function (key, decode) {
      if (!(key in json)) {
        return ;
      }
      try {
        return Caml_option.some(decode((json[key])));
      }
      catch (raw_msg){
        var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
        if (msg.RE_EXN_ID === DecodeError) {
          throw {
                RE_EXN_ID: DecodeError,
                _1: "" + msg._1 + "\n\tat field '" + key + "'",
                Error: new Error()
              };
        }
        throw msg;
      }
    };
    var required = function (key, decode) {
      if (!(key in json)) {
        throw {
              RE_EXN_ID: DecodeError,
              _1: "" + key + " required",
              Error: new Error()
            };
      }
      try {
        return decode((json[key]));
      }
      catch (raw_msg){
        var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
        if (msg.RE_EXN_ID === DecodeError) {
          throw {
                RE_EXN_ID: DecodeError,
                _1: "" + msg._1 + "\n\tat field '" + key + "'",
                Error: new Error()
              };
        }
        throw msg;
      }
    };
    return Curry._1(f, {
                optional: optional,
                required: required
              });
  };
}

function oneOf(decoders) {
  return function (json) {
    var errors = [];
    var _i = 0;
    while(true) {
      var i = _i;
      if (i >= decoders.length) {
        throw {
              RE_EXN_ID: DecodeError,
              _1: "All decoders given to oneOf failed. Here are all the errors:\n- " + errors.join("\n") + "\nAnd the JSON being decoded: " + JSON.stringify(json) + "",
              Error: new Error()
            };
      }
      var decode = decoders[i];
      try {
        return decode(json);
      }
      catch (raw_err){
        var err = Caml_js_exceptions.internalToOCamlException(raw_err);
        if (err.RE_EXN_ID === DecodeError) {
          errors.push(err._1);
          _i = i + 1 | 0;
          continue ;
        }
        throw err;
      }
    };
  };
}

function map(decode, f) {
  return function (json) {
    return f(decode(json));
  };
}

function decode(json, decode$1) {
  try {
    return {
            TAG: /* Ok */0,
            _0: decode$1(json)
          };
  }
  catch (raw_msg){
    var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
    if (msg.RE_EXN_ID === DecodeError) {
      return {
              TAG: /* Error */1,
              _0: msg._1
            };
    }
    throw msg;
  }
}

var pair = tuple2;

export {
  DecodeError ,
  $$Error ,
  custom ,
  id ,
  $$float ,
  $$int ,
  bool ,
  string ,
  array ,
  list ,
  object ,
  option ,
  date ,
  pair ,
  tuple2 ,
  tuple3 ,
  tuple4 ,
  dict ,
  field ,
  oneOf ,
  map ,
  decode ,
}
/* No side effect */
