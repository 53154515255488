


function pct(num) {
  return num.toString() + "%";
}

function deg(num) {
  return num.toString() + "deg";
}

function rad(num) {
  return num.toString() + "rad";
}

var FontWeight = {};

var empty = {};

export {
  pct ,
  deg ,
  rad ,
  FontWeight ,
  empty ,
}
/* No side effect */
